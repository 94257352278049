<template>
  <router-link
    v-if="('systemTagList' in templated && templated.systemTagList.length) || templated.fullName === 'Андреев Павел Викторович'|| templated.fullName === 'Andreyev Pavel Viktorovich'"
    tag="div" to="/staff_chamber?from=leader" class="card_wrapper"
    :style="`background: linear-gradient(0deg, rgba(7, 22, 36, 0.75) 12.73%, rgba(28, 73, 120, 0) 79.41%), url('${this.$store.getters.getUrlApi}${templated.photo}') center / cover`">
    <p v-text="templated.fullName"></p>
  </router-link>
  <router-link v-else-if="templated.photo" tag="div" :to="routePath" class="card_wrapper"
               :style="`background: linear-gradient(0deg, rgba(7, 22, 36, 0.75) 12.73%, rgba(28, 73, 120, 0) 79.41%), url('${this.$store.getters.getUrlApi}${templated.photo}') center / cover`">
    <p v-text="templated.fullName"></p>
  </router-link>
  <router-link v-else tag="div" :to="routePath" class="card_wrapper"
               :style="`background: linear-gradient(0deg, rgba(7, 22, 36, 0.75) 12.73%, rgba(28, 73, 120, 0) 79.41%), url('/assets/icon/photo_member.svg') center / cover`">
    <p v-text="templated.fullName"></p>
  </router-link>
</template>

<script>
export default {
  name: 'CardMemberBoard',
  props: {
    templated: {
      type: Object
    }
  },
  data: () => {
    return {
      disabledList: ['artamonov-oleg-nikolaevich', 'tochenov-aleksandr-sergeevich', 'stefanyuk-elena-ivanovna', 'kiryanov-artem-yurevich', 'melnikov-aleksey-vladimirovich']
    }
  },
  computed: {
    routePath () {
      const path = `/member_profile/${this.templated.slug}`
      if (this.templated.slug === 'velixov-evgenii-pavlovic') return path
      if (this.disabledList?.includes(this.templated.slug)) {
        return ''
      } else return path
    }
  }
}
</script>

<style scoped lang="scss">
.card_wrapper {
  display: flex;
  width: 12.1875rem;
  height: 15.0625rem;
  scroll-snap-align: start;
  cursor: pointer;
  padding: 1.3125rem 1.25rem;
  border-radius: 0.5rem;

  p {
    display: flex;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 157%;

    color: #FFFFFF;
    margin-top: auto;
    word-spacing: 10rem;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .card_wrapper {
    position: relative;

    p {
      position: absolute !important;
      bottom: 1rem !important;
    }
  }
}
</style>
